import { InspectionList } from './InspectionList';
import about1 from '../../../static/about/about1.png';
import about2 from '../../../static/about/about2.jpg';
import about3 from '../../../static/about/about3.jpg';

export const aboutUsCards: {
    title: string;
    description: string;
    reactInjection?: JSX.Element;
    image: string;
}[] = [
    {
        image: about1,
        title: 'Nadzory Budowlane',
        description:
            'Oferujemy prowadzenie wielobranżowego nadzoru inwestorskiego w zakresie inwestycji kubaturowych, inwestycji przemysłowych, nadzorów w zakresie renowacji zabytków dla inwestorów publicznych jak i prywatnych.'
    },
    {
        image: about2,
        title: 'Budowlane Inspekcje Lotnicze',
        description:
            'Firma jest na etapie wdrażania usług dronem w zakresie obsługi inwestycji i odwrotnej inżynierii',
        reactInjection: <InspectionList />
    },
    {
        image: about3,
        title: 'Wynajem sprzętu do suszenia budynków',
        description:
            'W trakcie realizacji przeróżnego rodzaju inwestycji budowlanych jak i remontów często występują przypadki dużego zawilgocenia lub wręcz zalania po awariach. \n' +
            'W tym przypadku proponujemy dostarczenie w formie wynajmu sprzętu do suszenia ścian posadzek. '
    }
];

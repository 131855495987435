import styled from 'styled-components';
import { Typography } from '@material-ui/core';

type StyledContactTypographyProps = {
    secondary?: number;
    fontWeight?: string;
};

export const StyledTypographyIconWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const StyledContactTypography = styled(Typography)<StyledContactTypographyProps>`
    font-weight: ${props => props.fontWeight};

    @media (min-width: 768px) {
        margin-left: ${(props) => props.secondary && `5px`};
    }
`;

export const StyledContactLink = styled.p`
    color: '#4267B2';

    &:hover {
        cursor: pointer;
    }

    @media (min-width: 768px) {
        margin-left: 10px;
    }
`;

export const StyledContactDivider = styled.div`
    margin: 2em 0;
`;

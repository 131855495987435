import { StyledScrollTopButton } from "./StyledScrollTopButton"
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';

export const ScrollTopButton = () => {
  const scrollTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  
  return (
    <StyledScrollTopButton onClick={scrollTop}>
      <ArrowUpwardOutlinedIcon />
    </StyledScrollTopButton>
  )
}

export default ScrollTopButton;
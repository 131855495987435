import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  onClick: any; 
}

const StyledCloseImageButton = styled(Button)`
  position:fixed;
  z-index: 102;
  top:2em;
  right:2em;
  font-size: 3em;
  width:2em;
  height:2em;

  @media(max-width: 768px) {
    display: none;
  }
`;

export const CloseImageButton = ({ onClick }: Props) => (
  <StyledCloseImageButton onClick={onClick}>
    <CloseIcon color="secondary" fontSize="inherit" />
  </StyledCloseImageButton>
)
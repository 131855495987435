import styled from 'styled-components';
import { FlexWrapper } from './FlexWrapper';

export const PageWrapper = styled(FlexWrapper)`
    max-width: 100vw;
    flex-direction: column;
    background-color: ${(props) => props.theme.background.colors.primary};
    min-height: ${(props) =>
        `calc(100vh - ${props.theme.constants.mobileNavbarLength} - ${props.theme.constants.footerLength})`};

    @media (min-width: 768px) {
        margin-top: ${(props) => props.theme.constants.desktopNavbarLength};
        min-height: ${(props) =>
            `calc(100vh - ${props.theme.constants.desktopNavbarLength} - ${props.theme.constants.footerLength})`};
    }
`;

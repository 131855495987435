import React from 'react';
import styled from 'styled-components';

type SpacerProps = {
    height: string;
};

export const StyledSpacer = styled.div<SpacerProps>`
    width: 100%;
    height: ${(props) => props.height};
    background-color: transparent;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Spacer = () => <StyledSpacer height="75vh" />;

import React, { useEffect } from 'react';
import { PageWrapper } from '../../common/components/PageWrapper';
import styled from 'styled-components';
import { LANDING } from '../../common/constants/routes';
import history from '../../common/services/history';

const MS_TO_REDIRECT = 5000;

export const NotFound = () => {
    useEffect(() => {
        setTimeout(() => {
            history.push(LANDING);
        }, MS_TO_REDIRECT);
    }, []);

    return (
        <PageWrapper>
            <StyledNotFoundWrapper>
                <StyledNotFoundBanner>404 - Nie znaleziono takiej strony</StyledNotFoundBanner>
                <StyledNotFoundBannerDescription>
                    Za chwilę przeniesiemy Cię do strony głównej.
                </StyledNotFoundBannerDescription>
            </StyledNotFoundWrapper>
        </PageWrapper>
    );
};

const StyledNotFoundWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-top: 100px;
    flex-direction: column;
`;

const StyledNotFoundBanner = styled.h2`
    font-weight: bold;
`;

const StyledNotFoundBannerDescription = styled.h4`
    margin-top: 10px;
    font-weight: bold;
`;

import styled from 'styled-components';
import video2 from '../static/background2.mp4';
import React from 'react';

const StyledBackgroundVideo = styled.video`
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    object-fit: cover;
    filter: brightness(0.8);

    @media (max-width: 768px) {
        display: none;
    }
`;

export const BackgroundVideo = () => {
    return (
        <StyledBackgroundVideo autoPlay={true} loop={true} muted={true}>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
        </StyledBackgroundVideo>
    );
};

import styled from 'styled-components';

type PageSectionProps = {
    ignoreLine?: number;
    invisibleOnMobile?: number;
};

export const PageSection = styled.section<PageSectionProps>`
    position: relative;
    display: flex;
    padding-top: 5em;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: white;

    flex-direction: column;
    align-items: center;
    ${(props) => {
        if (!props.ignoreLine) {
            return `
            &::before {
              position: absolute;
              top: -1em;
              left:10%;
              right:10%;
              height: 1px;
              background-color: #979797;
              content: '';
            }
          `;
        }
    }}

    @media (max-width: 768px) {
        padding-top: 2em;
        ${(props) => {
            if (!props.invisibleOnMobile) {
                return `
            &::before {
              position: absolute;
              top: -1em;
              left:10%;
              right:10%;
              height: 1px;
              background-color: #979797;
              content: '';
            }
          `;
            }
        }}
    }
`;

import React from 'react';
import styled from 'styled-components';

const StyledFacebookFanpageContainer = styled.div`
    @media (max-width: 768px) {
        margin-top: 2em;
        width: 100%;
        text-align: center;
    }
`;

export const FacebookFanpage = () => (
    <StyledFacebookFanpageContainer
        className="fb-page"
        data-href="https://www.facebook.com/Marbar-Project-109238094668616"
        data-tabs="timeline"
        data-width="300"
        data-height="400"
        data-small-header="false"
        data-adapt-container-width="false"
        data-hide-cover="false"
        data-show-facepile="false">
        <blockquote
            cite="https://www.facebook.com/Marbar-Project-109238094668616"
            className="fb-xfbml-parse-ignore">
            <a href="https://www.facebook.com/Marbar-Project-109238094668616">Marbar Project</a>
        </blockquote>
    </StyledFacebookFanpageContainer>
);

import { Typography } from '@material-ui/core';
import styled from 'styled-components';

type IntroducerProps = {
    ignoreUnderline?: number;
};

export const StyledSectionIntroducer = styled.div<IntroducerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    &:after {
        content: '';
        height: 1em;
        width: 5em;
        bottom: 0;
        background-color: ${(props) => !props.ignoreUnderline && `#ff9900`};
    }
`;

export const StyledSectionTitle = styled(Typography)`
    letter-spacing: 1px;
    margin-bottom: 10px;
`;

export const StyledSectionSubtitle = styled(Typography)`
    letter-spacing: 1px;

    max-width: 600px;
`;

import styled from "styled-components";

export type StyledNavbarProps = {
    scrolled: boolean,
}

export const StyledDesktopNavbar = styled.div<StyledNavbarProps>`
    position: fixed;
    display:none;
    top:0;
    left:0;
    right:0;
    background-color: ${props => props.theme.background.colors.secondary};

    justify-content: space-between;
    box-shadow: ${props => props.scrolled && `0 -5px 20px black`};

    z-index:99;

    @media(min-width: 768px) {
        display: flex;
    }
`;

export const StyledNavbarTitle = styled.h2`
    font-size: 2em;
    letter-spacing: 2px;
    padding: 0.5em 2em;
`;

type StyledNavbarLinkProps = {
    active: boolean | number;
}

export const StyledNavbarLink = styled.p<StyledNavbarLinkProps>`
    height: 100%;
    font-size: 1.2em;
    letter-spacing: 1px;
    padding: 1em 2em;
    text-decoration: ${props => props.active && `underline`};

    &:hover {
        cursor:pointer;
        filter: ${props => !props.active && `brightness(1.2)`};
    }
  
    ${props => props.active && `
        background-color: #cecece;
    `}
`;
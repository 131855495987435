import React from 'react';
import { Link } from '@material-ui/core';
import { FlexWrapper } from '../../components/FlexWrapper';
import { StyledNavbarLink, StyledNavbarTitle } from '../../components/Navigation/StyledNavbar';
import { StyledDesktopNavbar } from "../../components/Navigation/StyledNavbar";
import Logo from "../../../static/logo.jpeg";

type Props = {
    scrollPosition: number;
    activeElement?: string;
}

export const DesktopNavigation = ({ scrollPosition, activeElement }: Props) => {

    return (
        <StyledDesktopNavbar scrolled={scrollPosition > 0}>
            <Link href="/">
                <StyledNavbarTitle as="img" src={Logo} />
            </Link>

            <FlexWrapper>
                <Link href="/#aboutUs" color="inherit" >
                    <StyledNavbarLink active={activeElement === 'aboutUs' ? +true : +false}>
                        O nas
                    </StyledNavbarLink>
                </Link>

                <Link href="/#recentProjects" color="inherit" >
                    <StyledNavbarLink active={activeElement === 'recentProjects' ? +true : +false}>
                        Ostatnie realizacje
                    </StyledNavbarLink>
                </Link>

                <Link href="/#contactUs" color="inherit" >
                    <StyledNavbarLink active={activeElement === 'contactUs' ? +true : +false}>
                        Kontakt
                    </StyledNavbarLink>
                </Link>

            </FlexWrapper>
        </StyledDesktopNavbar>
    )
}
import React from 'react';
import styled from 'styled-components';

const StyledInstagramFeed = styled.div`
    width: 60%;
    margin-left: auto;
    margin-right: auto;
`;

export const InstagramFeed = () => {
    return (
        <StyledInstagramFeed
            className="embedsocial-hashtag"
            data-ref="3a44a7c08886a40ad415c68c6585defae13ca705"
        />
    );
};

export default InstagramFeed;

import project1 from '../../../static/projects/project1.png'
import project2 from '../../../static/projects/project2.png'
import project3 from '../../../static/projects/project3.jpg'
import project4 from '../../../static/projects/project4.png'
import project5 from '../../../static/projects/project5.png'
import project6 from '../../../static/projects/project6.jpg'

export const images = [
  {
    original: project1,
    thumbnail: project1
  },
  {
    original: project2,
    thumbnail: project2
  },
  {
    original: project3,
    thumbnail: project3
  },
  {
    original: project4,
    thumbnail: project4
  },
  {
    original: project5,
    thumbnail: project5
  },
  {
    original: project6,
    thumbnail: project6
  },
  // {    
  //   original: 'https://via.placeholder.com/400x300?text=Project',
  //   thumbnail: 'https://via.placeholder.com/250x150?text=Project'
  // },
];
import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { FlexWrapper } from '../../common/components/FlexWrapper';
import styled from 'styled-components';
import Image from 'material-ui-image';

type Props = {
    image: string;
    title: string;
    description: string;
    reactInjection?: JSX.Element;
};

const StyledCardWrapper = styled(Card)`
    height: 100%;
`;

const StyledCard = styled(CardContent)`
    display: flex;
    align-items: flex-start;
    height: 100%;
`;

const Description = styled(Typography)`
    line-height: 1.5;
`;

const StyledDescriptionWrapper = styled(FlexWrapper)`
    flex-direction: column;
`;

export const AboutUsCard = ({ title, description, reactInjection, image }: Props) => (
    <StyledCardWrapper>
        <Image src={image} aspectRatio={16 / 9} disableSpinner />
        <StyledCard>
            <StyledDescriptionWrapper direction="column">
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Description variant="subtitle1">
                    {description}
                    {reactInjection}
                </Description>
            </StyledDescriptionWrapper>
        </StyledCard>
    </StyledCardWrapper>
);

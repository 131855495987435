import { FormControl } from "@material-ui/core";
import styled from "styled-components";

type StyledFormControlProps = {
  inlineright?: boolean | number;
  inlineleft?: boolean | number;
}
export const StyledFormControl = styled(FormControl)<StyledFormControlProps>`
  margin: 10px 0;

  margin-left: ${props => props.inlineright && `5px`};
  margin-right: ${props => props.inlineleft && `5px`};
  
`;

export const StyledContactForm = styled.form`
  @media(max-width: 768px) {
    margin-top: 2em;
  }
`;
import { Grid } from '@material-ui/core';
import React, { useState, useCallback, Fragment } from 'react';
import { StyledGrid } from '../../../common/components/Defaults/StyledGrid';
import { PageSection } from '../../../common/components/PageSection';
import { SectionIntroducer } from '../../../common/components/SectionIntroducer';
import { SeeMoreButton } from '../../../common/components/SeeMoreButton';
import { StyledHeaderImage } from '../../../components/Header/StyledHeader';
import { NavSectionProps } from '../../../common/interfaces/NavSectionProps';
import { images } from './images.mock';
import ImageViewer from 'react-simple-image-viewer';
import { CloseImageButton } from '../../../components/RecentProjects/CloseImageButton';

export const RecentProjects = ({ r }: NavSectionProps) => {
    const title = 'Ostatnie realizacje';
    const subtitle = '';

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <PageSection id="recentProjects" ref={r}>
            <SectionIntroducer title={title} subtitle={subtitle} />

            <StyledGrid container direction="row" justify="center" alignItems="center" spacing={3}>
                {images.map((image, i) => (
                    <Grid key={`recent_projects_grid_${i}`} item xs={12} sm={12} md={4}>
                        <StyledHeaderImage
                            src={image.original}
                            aspectRatio={16 / 10}
                            disableSpinner
                            onClick={() => openImageViewer(i)}
                        />
                    </Grid>
                ))}
            </StyledGrid>

            <SeeMoreButton />

            {isViewerOpen && (
                <Fragment>
                    <ImageViewer
                        src={images.map((img) => img.original)}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        backgroundStyle={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }}
                    />
                    <CloseImageButton onClick={closeImageViewer} />
                </Fragment>
            )}
        </PageSection>
    );
};

import React from 'react';
import ReactDOM from 'react-dom';
import Landing from './containers/Landing';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Navigation from './common/containers/Navigation';
import { createMuiTheme, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { Footer } from './common/containers/Footer';
import { StateProvider } from './common/useGlobalState';
import { Router, Route, Switch } from 'react-router-dom';
import { LANDING, PROJECTS } from './common/constants/routes';
import { Projects } from './containers/Projects';
import { NotFound } from './containers/NotFound';
import history from './common/services/history';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif; 
    scroll-behavior: smooth;
    }
  body {
    color: 'black';
    background-color: 'whitesmoke';
  }
`;
const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#0000000'
        },
        secondary: {
            main: '#FFFFFF'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    },
    overrides: {
        MuiTypography: {
            h2: {
                fontWeight: 400
            }
        }
    }
});

const theme = {
    text: {
        colors: {
            primary: 'black',
            facebook: '#4267B2'
        }
    },
    background: {
        colors: {
            primary: 'whitesmoke',
            secondary: 'white',
            outline: '#ff9900'
        }
    },
    constants: {
        footerLength: '10em',
        mobileNavbarLength: '48px',
        desktopNavbarLength: '71px'
    }
};

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={muiTheme}>
                    <ThemeProvider theme={theme}>
                        <StateProvider>
                            <GlobalStyle />
                            <Navigation />
                            <Switch>
                                <Route exact path={LANDING} component={Landing} />
                                <Route exact path={PROJECTS} component={Projects} />
                                <Route component={NotFound} />
                            </Switch>
                            <Footer />
                        </StateProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Fragment, useEffect, useState } from "react";
import { DesktopNavigation } from "./DesktopNavigation";
import { MobileNavigation } from "./MobileNavigation";
import { useStore } from '../../useStore';
import ScrollTopButton from "../../components/ScrollTop";

const MIN_VISIBILITY_FOR_SCROLL_TOP_BUTTON = 500;

export const Navigation = () => {
  const { positions }: { [key:string]: number } = useStore();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarActiveElement, setNavbarActiveElement] = useState<string | undefined>('');

  useEffect(() => {
      const getNavbarActiveElements = (position: number) => {
          let currentNavbarEl = undefined;
          for(const [navbarElName, navbarElPos] of Object.entries(positions) ) {
              if(position >= navbarElPos) {
                  currentNavbarEl = navbarElName;
              }
          }
          setNavbarActiveElement(currentNavbarEl);
      }

      const handleScroll = () => {
          const position = window.pageYOffset;
          setScrollPosition(position);
          getNavbarActiveElements(position);
      };

      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [positions]);

  return (
    <Fragment>
      { MIN_VISIBILITY_FOR_SCROLL_TOP_BUTTON <= scrollPosition && <ScrollTopButton /> }
      <DesktopNavigation scrollPosition={scrollPosition} activeElement={navbarActiveElement}/>
      <MobileNavigation scrollPosition={scrollPosition} activeElement={navbarActiveElement}/>
    </Fragment>
  )
}

export default Navigation;
import styled from 'styled-components';
import { FlexWrapper } from '../FlexWrapper';

export const StyledFooter = styled(FlexWrapper)`
    position: absolute;
    right: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    height: ${(props) => props.theme.constants.footerLength};
    background-color: whitesmoke;
    z-index: 98;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

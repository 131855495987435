import styled from "styled-components";

type Props = {
    direction?: string;
    justifyContent?: string;
    width?: string;
    alignItems?: string;
}

export const FlexWrapper = styled.div<Props>`
    display: flex;
    width: ${props => props.width};
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
`;
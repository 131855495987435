import { FlexWrapper } from '../../common/components/FlexWrapper';
import {
    StyledContactTypography,
    StyledContactDivider,
    StyledContactLink,
    StyledTypographyIconWrapper
} from './StyledContactInfo';
import FacebookIcon from '@material-ui/icons/Facebook';
import React from 'react';
import { Link } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import MailTo from '../../common/components/Defaults/MailTo';

export const ContactInfo = () => (
    <div>
        <StyledContactTypography variant="h5" gutterBottom>
            MARBAR-PROJECT SP. Z O.O.
        </StyledContactTypography>

        <StyledContactDivider>
            <StyledContactTypography secondary={+true} variant="body1">
                ul. Cedrowa 7
            </StyledContactTypography>
            <StyledContactTypography secondary={+true} variant="body1">
                62-020 Zalasewo
            </StyledContactTypography>
            <StyledContactTypography secondary={+true} variant="body1">
                NIP: 777-32-45-635
            </StyledContactTypography>
            <StyledContactTypography secondary={+true} variant="body1">
                KRS: 0000540511
            </StyledContactTypography>
            <StyledContactTypography secondary={+true} variant="body1" gutterBottom>
                REGON: 360617397
            </StyledContactTypography>
        </StyledContactDivider>

        <StyledContactDivider>
            <StyledContactTypography fontWeight="500" variant="h6">
                Nadzory Budowlane:
            </StyledContactTypography>
            <StyledTypographyIconWrapper>
                <CallOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1">
                    Tel. 793-759-880
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
            <StyledTypographyIconWrapper>
                <EmailOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1" gutterBottom>
                    <MailTo href="mailto:bbryk@marbar-project.pl">bbryk@marbar-project.pl</MailTo>
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
        </StyledContactDivider>

        <StyledContactDivider>
            <StyledContactTypography fontWeight="500" variant="h6">
                Wynajem Sprzętu:
            </StyledContactTypography>
            <StyledTypographyIconWrapper>
                <CallOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1">
                    Tel. 697-678-740
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
            <StyledTypographyIconWrapper>
                <EmailOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1" gutterBottom>
                    <MailTo href="mailto:osuszacze@marbar-project.pl">
                        osuszacze@marbar-project.pl
                    </MailTo>
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
        </StyledContactDivider>

        <FlexWrapper alignItems="center" style={{ color: '#4267B2' }}>
            <FacebookIcon fontSize="large" />
            <Link href="https://www.facebook.com/Marbar-Project-109238094668616" target="_blank">
                <StyledContactLink>
                    <StyledContactTypography variant="button">
                        Śledź nas na Facebooku
                    </StyledContactTypography>
                </StyledContactLink>
            </Link>
        </FlexWrapper>
    </div>
);

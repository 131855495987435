import { Button, Typography, Link } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { PROJECTS } from "../constants/routes";
import { useHistory } from "react-router-dom";

const StyledSeeMoreButton = styled(Button)`
  padding: 1em;
`;

export const SeeMoreButton = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(PROJECTS);
  }
 
  return (
    <StyledSeeMoreButton variant="outlined" color="primary" onClick={handleClick}>
        <Link color="inherit" href={PROJECTS} underline="none">
          <Typography variant="h6">
              Zobacz więcej
          </Typography>
        </Link>
    </StyledSeeMoreButton>
  )
}
import styled from 'styled-components';
import Image from 'material-ui-image';

type StyledHeaderImageProps = {
    header?: boolean | number;
};

export const StyledHeaderImage = styled(Image)<StyledHeaderImageProps>`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;

    transition: 200ms;

    ${(props) =>
        !props.header &&
        `
    &:hover { 
      cursor: pointer;
      filter: brightness(1.1);
    }
  `}
`;

import React, { useState } from 'react'

const defaultContext = {};

export const Context = React.createContext<{[key: string]: any}>(defaultContext)

export const useGlobalState = () => {
    const [positions, setPositions] = useState<{[key: string]: string}>({});

    return {
        positions, setPositions
    }
}

export const StateProvider = ({ children }: any) => {
    const store = useGlobalState();
    return (
        <Context.Provider value={store}>
            {children}
        </Context.Provider>
    )
};

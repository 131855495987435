import React from 'react';
import { PageWrapper } from '../../common/components/PageWrapper';
import 'react-image-gallery/styles/css/image-gallery.css';
import { SectionIntroducer } from '../../common/components/SectionIntroducer';
import { InstagramFeed } from '../../components/Instagram/InstagramFeed';
import { StyledSpacer } from '../Landing/Spacer';

export const Projects = () => {
    const title = 'Nasze projekty na Instagramie';
    const subtitle = '';
    return (
        <PageWrapper>
            <StyledSpacer height={'50px'} />
            <SectionIntroducer title={title} subtitle={subtitle} ignoreUnderline={+true} />
            <InstagramFeed />
        </PageWrapper>
    );
};

import { FlexWrapper } from '../../common/components/FlexWrapper';
import styled from 'styled-components';

export const StyledContactUs = styled(FlexWrapper)`
    width: 100%;
    margin: 2em 0;
    flex-direction: column-reverse;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-evenly;
    }
`;

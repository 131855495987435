import React from 'react';
import { StyledFooter } from '../../components/Footer/StyledFooter';
import { FlexWrapper } from '../../components/FlexWrapper';
import { Link, Typography } from '@material-ui/core';
import { StyledFooterMenuLink } from '../../components/Footer/StyledFooterMenu';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import {
    StyledContactTypography,
    StyledTypographyIconWrapper
} from '../../../components/ContactUs/StyledContactInfo';
import MailTo from '../../components/Defaults/MailTo';

export const Footer = () => (
    <StyledFooter>
        <FlexWrapper direction="column">
            <Typography variant="h6" gutterBottom>
                MARBAR-PROJECT SP. Z O.O.
            </Typography>
            <StyledTypographyIconWrapper>
                <CallOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1">
                    Tel. 793-759-880 | 697-678-740
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
            <StyledTypographyIconWrapper>
                <EmailOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1">
                    <MailTo href="mailto:bbryk@marbar-project.pl">bbryk@marbar-project.pl</MailTo>
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
            <StyledTypographyIconWrapper>
                <EmailOutlinedIcon />
                <StyledContactTypography secondary={+true} variant="body1">
                    <MailTo href="mailto:osuszacze@marbar-project.pl">
                        osuszacze@marbar-project.pl
                    </MailTo>
                </StyledContactTypography>
            </StyledTypographyIconWrapper>
        </FlexWrapper>
        <FlexWrapper>
            <Link href="#aboutUs">
                <StyledFooterMenuLink>O nas</StyledFooterMenuLink>
            </Link>
            <Link href="#recentProjects">
                <StyledFooterMenuLink>Ostatnie realizacje</StyledFooterMenuLink>
            </Link>
            <Link href="#contactUs">
                <StyledFooterMenuLink>Kontakt</StyledFooterMenuLink>
            </Link>
        </FlexWrapper>
    </StyledFooter>
);

import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { FlexWrapper } from '../FlexWrapper';
import { StyledNavbarProps } from './StyledNavbar';

export const StyledMobileNavbar = styled.div<StyledNavbarProps>`
    display: flex;
    width: 100%;
    min-height: 3em;
    justify-content: flex-end;
    background-color: ${(props) => props.theme.background.colors.primary};
    box-shadow: 0 -5px 20px black;

    z-index: 100;

    @media (min-width: 768px) {
        display: none;
    }
`;

export const StyledMobileContent = styled(FlexWrapper)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding-top: 3em;
    flex-direction: column;
    background-color: ${(props) => props.theme.background.colors.primary};

    z-index: 100;
`;

type MobileBurgerProps = {
    backgroundVisible: boolean;
};

export const StyledMobileBurger = styled(IconButton)<MobileBurgerProps>`
    position: fixed;
    right: 1em;
    z-index: 101;

    ${(props) =>
        props.backgroundVisible &&
        `
        background-color: #efefef;
        &:hover {
            background-color: #e3e3e3;
        }
  `}
`;

import { Grid } from '@material-ui/core';
import React from 'react';
import { StyledGrid } from '../../../common/components/Defaults/StyledGrid';
import { PageSection } from '../../../common/components/PageSection';
import { SectionIntroducer } from '../../../common/components/SectionIntroducer';
import { AboutUsCard } from '../../../components/AboutUs/AboutUsCard';
import { NavSectionProps } from '../../../common/interfaces/NavSectionProps';
import { aboutUsCards } from './cards.config';

export const AboutUs = ({ r }: NavSectionProps) => {
    const title = 'O nas ';
    const subtitle = '';

    return (
        <PageSection id="aboutUs" ignoreLine={+true} invisibleOnMobile={+true} ref={r}>
            <SectionIntroducer title={title} subtitle={subtitle} />
            <StyledGrid container direction="row" justify="center" alignItems="stretch" spacing={3}>
                {aboutUsCards.map((entry, i) => (
                    <Grid key={`about_us_grid_${i}`} item xs={12} sm={6} md={4}>
                        <AboutUsCard
                            image={entry.image}
                            title={entry.title}
                            description={entry.description}
                            reactInjection={entry.reactInjection}
                        />
                    </Grid>
                ))}
            </StyledGrid>
        </PageSection>
    );
};

import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledScrollTopButton = styled(Button)`
  position: fixed;
  height: 3.5em;
  min-width: 3.5em;
  bottom: 1em;
  right: 1em;
  background-color: #ee8f00;
  color: ${props => props.theme.background.colors.primary};
  border-radius: 1em;
  z-index: 99;

  &:focus, &:hover {
    background-color: #ee8f00;
  }
`;
import React, { useEffect, useRef } from 'react';
import { BackgroundVideo } from '../../common/backgroundVideo';
import { PageWrapper } from '../../common/components/PageWrapper';
import { useStore } from '../../common/useStore';
import { AboutUs } from './AboutUs';
import { ContactUs } from './ContactUs';
import { Header } from './Header';
import { RecentProjects } from './RecentProjects';
import { Spacer } from './Spacer';

function Landing() {
    const { positions, setPositions } = useStore();

    const aboutUs = useRef<HTMLElement>(null);
    const recentProjects = useRef<HTMLElement>(null);
    const contactUs = useRef<HTMLElement>(null);

    useEffect(() => {
        const setPositionBoxData = (refObject: React.RefObject<HTMLElement>, refName: string) => {
            if (refObject) {
                const refObjectPos = refObject?.current?.offsetTop;
                const windowHeight = window.innerHeight;
                if (refObjectPos) {
                    setPositions(
                        Object.assign(positions, { [refName]: refObjectPos - windowHeight / 2 })
                    );
                }
            }
        };

        setPositionBoxData(aboutUs, 'aboutUs');
        setPositionBoxData(recentProjects, 'recentProjects');
        setPositionBoxData(contactUs, 'contactUs');
    }, [aboutUs, positions, setPositions]);

    return (
        <>
            <BackgroundVideo />
            <PageWrapper>
                <Header />
                <AboutUs r={aboutUs} />
                <RecentProjects r={recentProjects} />
                <Spacer />
                <ContactUs r={contactUs} />
            </PageWrapper>
        </>
    );
}

export default Landing;

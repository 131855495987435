import { Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import {
    StyledMobileBurger,
    StyledMobileContent,
    StyledMobileNavbar
} from '../../components/Navigation/StyledMobileNavbar';
import { StyledNavbarLink } from '../../components/Navigation/StyledNavbar';

type MobileProps = {
    scrollPosition: number;
    activeElement?: string;
};

export const MobileNavigation = ({ scrollPosition, activeElement }: MobileProps) => {
    const [open, setOpen] = useState(false);

    return (
        <StyledMobileNavbar scrolled={scrollPosition > 0}>
            <StyledMobileBurger
                backgroundVisible={scrollPosition > 0}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(!open)}>
                {open ? <CloseIcon /> : <MenuIcon />}
            </StyledMobileBurger>
            <MobileNavigationContent open={open} setOpen={setOpen} activeElement={activeElement} />
        </StyledMobileNavbar>
    );
};

type Props = {
    open: boolean;
    setOpen: Function;
    activeElement?: string;
};

const MobileNavigationContent = ({ open, setOpen, activeElement }: Props) => {
    const navigate = (event: any) => {
        setOpen(false);
    };

    return open ? (
        <StyledMobileContent>
            <Link href="/#aboutUs" onClick={navigate} color="inherit">
                <StyledNavbarLink active={activeElement === 'aboutUs' ? +true : +false}>
                    O nas
                </StyledNavbarLink>
            </Link>

            <Link href="/#recentProjects" onClick={navigate} color="inherit">
                <StyledNavbarLink active={activeElement === 'recentProjects' ? +true : +false}>
                    Ostatnie realizacje
                </StyledNavbarLink>
            </Link>

            <Link href="/#contactUs" onClick={navigate} color="inherit">
                <StyledNavbarLink active={activeElement === 'contactUs' ? +true : +false}>
                    Kontakt
                </StyledNavbarLink>
            </Link>
        </StyledMobileContent>
    ) : null;
};

import { Button, FormGroup, FormHelperText, TextField, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FlexWrapper } from '../../common/components/FlexWrapper';
import { StyledFormControl, StyledContactForm } from './StyledContactForm';
import { init, send } from 'emailjs-com';

const MAILING_TEMPLATE_ID = 'template_qsiqb2b';
const { REACT_APP_EMAIL_JS_USER_ID, REACT_APP_EMAIL_JS_SERVICE_ID } = process.env;

interface FormData {
    email: string;
    name: string;
    phone: number;
    message: string;
}

export const ContactForm = () => {
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        REACT_APP_EMAIL_JS_USER_ID && init(REACT_APP_EMAIL_JS_USER_ID);
    }, []);

    const onSubmit = async (data: FormData) => {
        await sendFeedback(MAILING_TEMPLATE_ID, data);
    };

    const sendFeedback = async (templateId: string, variables: FormData) => {
        try {
            if (!REACT_APP_EMAIL_JS_SERVICE_ID) throw new Error('No service id provided');
            await send(
                REACT_APP_EMAIL_JS_SERVICE_ID,
                templateId,
                (variables as unknown) as Record<string, unknown>
            );
            alert(
                'Dziękujemy za wysłanie maila. Potwierdzenie otrzymasz na swoją skrzynkę pocztową.'
            );
        } catch (err) {
            alert(
                'Przepraszamy, ale wystąpił błąd. \n W celu skontaktowania się z nami możesz wysłać wiadomość pod adres: bbryk@marbar-project.pl'
            );
            console.error(
                'Oh well, you failed. Here some thoughts on the error that occurred:',
                err
            );
        }
    };

    return (
        <StyledContactForm onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Typography variant="button">Napisz do nas</Typography>

                <StyledFormControl>
                    <TextField
                        error={!!errors.email}
                        type="email"
                        name="email"
                        inputRef={register({
                            required: true
                        })}
                        required
                        label="Adres e-mail"
                        aria-describedby="adres email"
                    />
                    <FormHelperText>Nie przechowujemy Twojego adresu e-mail.</FormHelperText>
                </StyledFormControl>

                <FlexWrapper>
                    <StyledFormControl inlineleft={+true}>
                        <TextField
                            error={!!errors.name}
                            type="name"
                            name="name"
                            inputRef={register({
                                required: true
                            })}
                            required
                            label="Imię i nazwisko"
                            aria-describedby="name"
                        />
                    </StyledFormControl>
                    <StyledFormControl inlineright={+true}>
                        <TextField
                            error={!!errors.phone}
                            type="phone"
                            name="phone"
                            inputRef={register({
                                required: true
                            })}
                            required
                            label="Telefon"
                            aria-describedby="phone"
                        />
                    </StyledFormControl>
                </FlexWrapper>

                <StyledFormControl>
                    <TextField
                        error={!!errors.message}
                        type="message"
                        name="message"
                        inputRef={register({
                            required: true,
                            minLength: 3,
                            maxLength: 500
                        })}
                        required
                        label="Wiadomość"
                        multiline
                        rows={8}
                        aria-describedby="message"
                    />
                    {errors?.message?.type === 'minLength' && (
                        <FormHelperText>Tekst musi zawierać minimum 3 znaki.</FormHelperText>
                    )}
                    {errors?.message?.type === 'maxLength' && (
                        <FormHelperText>Tekst może zawierać maksimum 500 znaków.</FormHelperText>
                    )}
                </StyledFormControl>

                <Button variant="outlined" type="submit">
                    Wyślij
                </Button>
            </FormGroup>
        </StyledContactForm>
    );
};

import React from 'react';
import { PageSection } from '../../../common/components/PageSection';
import { SectionIntroducer } from '../../../common/components/SectionIntroducer';
import { ContactForm } from '../../../components/ContactUs/ContactForm';
import { ContactInfo } from '../../../components/ContactUs/ContactInfo';
import { StyledContactUs } from '../../../components/ContactUs/StyledContactUs';
import { NavSectionProps } from '../../../common/interfaces/NavSectionProps';
import styled from 'styled-components';
import { FacebookFanpage } from '../../../components/Facebook/Fanpage';

const StyledIframe = styled.iframe`
    width: 70%;

    @media (max-width: 768px) {
        margin-top: 2em;
        width: 100%;
    }
`;

export const ContactUs = ({ r }: NavSectionProps) => {
    const title = 'Skontaktuj się z nami';
    const subtitle = '';

    return (
        <PageSection id="contactUs" ref={r} ignoreLine={+true}>
            <SectionIntroducer title={title} subtitle={subtitle} />
            <StyledContactUs>
                <ContactInfo />
                <ContactForm />
            </StyledContactUs>
            <StyledContactUs>
                <FacebookFanpage />
                <StyledIframe
                    height="400"
                    src="https://maps.google.com/maps?q=cedrowa%207,%20zalasewo&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                />
            </StyledContactUs>
        </PageSection>
    );
};

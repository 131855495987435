import {
    StyledSectionIntroducer,
    StyledSectionSubtitle,
    StyledSectionTitle
} from './Section/StyledSection';

type Props = {
    title: string;
    subtitle: string;
    ignoreUnderline?: number;
};

export const SectionIntroducer = ({ title, subtitle, ignoreUnderline }: Props) => (
    <StyledSectionIntroducer ignoreUnderline={ignoreUnderline}>
        <StyledSectionTitle variant="h2" gutterBottom>
            {title}
        </StyledSectionTitle>

        <StyledSectionSubtitle variant="h5">{subtitle}</StyledSectionSubtitle>
    </StyledSectionIntroducer>
);

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
export const InspectionList = () => (
    <List dense>
        W ramach usługi proponujemy:
        <ListItem>
            <ListItemIcon>
                <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Monitoring postępu prac" />
        </ListItem>
        <ListItem>
            <ListItemIcon>
                <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Inspekcje dostępu trudnych i niebezpiecznych elementów budowy" />
        </ListItem>
        {/*<ListItem>*/}
        {/*    W ramach "odwrotnej inżynierii" proponujemy usługi skanowania obiektów budowlany przy*/}
        {/*    użyciu drona dla sprawdzenia zrealizowanych inwestycji w porównaniu z projektem*/}
        {/*</ListItem>*/}
    </List>
);

import React from 'react';
import styled from 'styled-components';
import { StyledSectionSubtitle } from '../../../common/components/Section/StyledSection';
import mobileBackground from '../../../static/mobilebackground2.jpg';

const StyledHeader = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    z-index: 9;
    min-height: 95vh;
`;

const StyledHeaderTextWrapper = styled.div`
    text-align: center;
    padding: 2em 3em;
    background-color: #0000009e;

    @media (max-width: 768px) {
        padding: 2em 5px;
    }
`;

const StyledHeaderMobileImage = styled.img`
    max-width: 100%;

    @media (min-width: 768px) {
        display: none;
    }
`;

const StyledArrowDown = styled.a`
    position: absolute;
    bottom: 6em;
    z-index: 99;
    border: solid whitesmoke;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    animation-name: move;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        animation-play-state: paused;
    }

    @media (max-width: 768px) {
        display: none;
    }

    @keyframes move {
        0% {
            bottom: 6em;
        }
        40% {
            bottom: 5.5em;
        }
        75% {
            bottom: 6em;
        }
    }
`;

export const Header = () => {
    const title = 'Marbar Project';
    const subtitle =
        'Funkcjonujemy na rynku budowlanym już od 1999 roku. Dotychczasowa działalność opierała się głównie o nadzory budowlane (kierownik budowy), nadzory inwestorskie. Obecnie prowadzimy nadzory inwestorskie wielobranżowe, pełnimy funkcję Inżyniera Kontraktu przy inwestycjach publicznych jak i w budownictwie mieszkaniowym i przemysłowym.';
    return (
        <StyledHeader>
            <StyledHeaderMobileImage src={mobileBackground} />
            <StyledHeaderTextWrapper>
                <StyledSectionSubtitle variant="h2" gutterBottom>
                    {title}
                </StyledSectionSubtitle>
                <StyledSectionSubtitle variant="h5">{subtitle}</StyledSectionSubtitle>
            </StyledHeaderTextWrapper>
            <StyledArrowDown href="/#aboutUs" />
        </StyledHeader>
    );
};
